<template>
  <div
    :id="'div_notice' + $route.query.messageNum"
    :style="{ width: previewInfo.widthSize + 'px', maxHeight: '632px' }"
  >
    <div class="popup_cont" :id="'div_cont' + $route.query.messageNum">
      <div>
        <!-- [s] 내용 영역 -->
        <div
          :class="[ 'content_box', 'winpop', previewInfo.templUseYn === 'N' ? 'mt0' : 't2' ]"
          :style="[ previewInfo.templUseYn === 'Y'
            ? { 'background-color': $ekmtcCommon.setPopupBgColor(previewInfo.templBtypeCd), 'overflow-y': 'auto' }
            : { padding: '0px !important', height: previewInfo.heightSize + 'px', backgroundSize: 'cover'} ]"
        >
          <div>
            <div v-if="previewInfo.templUseYn === 'N'" :style="{height: previewInfo.heightSize + 'px', paddingBottom: '2px'}">
              <!-- 이미지 일때 -->
              <img :src="previewInfo.imgSrc" :style="{ width: '100%', height: '100%', objectFit: 'contain' }">
            </div>
            <div v-if="previewInfo.templUseYn === 'Y'">
              <!-- 템플릿 일때 -->
              <div class="tit t2">{{ previewInfo.messageCont }}</div>
              <div v-html="previewInfo.messageDtl" />
            </div>
          </div>
        </div>
        <!-- [s] 버튼 영역 -->
        <div
          v-if="previewInfo.btn1Nm || previewInfo.btn2Nm"
          class="btn_area"
        >
          <a
            v-if="previewInfo.btn1Nm && previewInfo.btn1Nm.trim()"
            :href="previewInfo.btn1Url"
            target="_blank"
            class="button md t2 blue"
          >{{ previewInfo.btn1Nm }}</a>
          <a
            v-if="previewInfo.btn2Nm && previewInfo.btn2Nm.trim()"
            :href="previewInfo.btn2Url"
            target="_blank"
            class="button md t2 blue"
          >{{ previewInfo.btn2Nm }}</a>
        </div>
      </div>
      <!-- [s] footer 영역 -->
      <div class="bottom_chk_wrap">
        <ul>
          <li>
            <input type="checkbox" id="chk1" @click="cookieClose()">
            <label for="chk1">{{ $t('common.NOTICE_POP.001') }}</label>
          </li>
          <li>
            <button
              type="button"
              @click="closeWindow()"
            >
              {{ $t('msg.MAIN100.228') }}
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import notices from '@/api/services/notices'
export default {
  name: 'NoticePop',
  props: {
    messageNum: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      previewInfo: {}
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      const messageNum = this.$route.query.messageNum

      notices.getPopupDetailInfo(messageNum).then(res => {
        this.previewInfo = res.data

        if (this.previewInfo.templUseYn === 'N') {
          this.previewInfo.imgSrc = `${process.env.VUE_APP_COMMON_URL}/commons/file-download/commons?messageNum=${this.previewInfo.messageNum}&type=POP_MNG`
        }

        if (this.$ekmtcCommon.isNotEmpty(this.previewInfo.imgSrc)) {
          const widthSize = Number(this.previewInfo.widthSize)
          const heightSize = Number(this.previewInfo.heightSize)
          this.previewInfo.orgWidthSize = widthSize
          this.previewInfo.orgHeightSize = heightSize
          // this.previewInfo.widthSize = widthSize + 2
          // this.previewInfo.heightSize = Number(heightSize) + 5
        }

        this.$nextTick(() => {
          const body = document.querySelector('#div_notice' + messageNum).closest('body')

          body.style.minWidth = 'inherit'
          body.style.overflow = 'hidden'
          const width = document.querySelector('#div_cont' + messageNum).offsetWidth
          const height = document.querySelector('#div_cont' + messageNum).offsetHeight

          window.resizeTo(width + 15, height + 74)
        })
      })
    },
    closeWindow () {
      window.close()
    },
    cookieClose () {
      const today = new Date()
      today.setDate(today.getDate() + 1)

      const messageNum = this.previewInfo.messageNum

      const cookieKey = 'notice' + messageNum
      document.cookie = cookieKey + '=' + escape(cookieKey) + '; path=/; expires=' + today.toUTCString() + ';'
      window.close()
    }
  }
}
</script>
