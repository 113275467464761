var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      style: { width: _vm.previewInfo.widthSize + "px", maxHeight: "632px" },
      attrs: { id: "div_notice" + _vm.$route.query.messageNum },
    },
    [
      _c(
        "div",
        {
          staticClass: "popup_cont",
          attrs: { id: "div_cont" + _vm.$route.query.messageNum },
        },
        [
          _c("div", [
            _c(
              "div",
              {
                class: [
                  "content_box",
                  "winpop",
                  _vm.previewInfo.templUseYn === "N" ? "mt0" : "t2",
                ],
                style: [
                  _vm.previewInfo.templUseYn === "Y"
                    ? {
                        "background-color": _vm.$ekmtcCommon.setPopupBgColor(
                          _vm.previewInfo.templBtypeCd
                        ),
                        "overflow-y": "auto",
                      }
                    : {
                        padding: "0px !important",
                        height: _vm.previewInfo.heightSize + "px",
                        backgroundSize: "cover",
                      },
                ],
              },
              [
                _c("div", [
                  _vm.previewInfo.templUseYn === "N"
                    ? _c(
                        "div",
                        {
                          style: {
                            height: _vm.previewInfo.heightSize + "px",
                            paddingBottom: "2px",
                          },
                        },
                        [
                          _c("img", {
                            style: {
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                            },
                            attrs: { src: _vm.previewInfo.imgSrc },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm.previewInfo.templUseYn === "Y"
                    ? _c("div", [
                        _c("div", { staticClass: "tit t2" }, [
                          _vm._v(_vm._s(_vm.previewInfo.messageCont)),
                        ]),
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(_vm.previewInfo.messageDtl),
                          },
                        }),
                      ])
                    : _vm._e(),
                ]),
              ]
            ),
            _vm.previewInfo.btn1Nm || _vm.previewInfo.btn2Nm
              ? _c("div", { staticClass: "btn_area" }, [
                  _vm.previewInfo.btn1Nm && _vm.previewInfo.btn1Nm.trim()
                    ? _c(
                        "a",
                        {
                          staticClass: "button md t2 blue",
                          attrs: {
                            href: _vm.previewInfo.btn1Url,
                            target: "_blank",
                          },
                        },
                        [_vm._v(_vm._s(_vm.previewInfo.btn1Nm))]
                      )
                    : _vm._e(),
                  _vm.previewInfo.btn2Nm && _vm.previewInfo.btn2Nm.trim()
                    ? _c(
                        "a",
                        {
                          staticClass: "button md t2 blue",
                          attrs: {
                            href: _vm.previewInfo.btn2Url,
                            target: "_blank",
                          },
                        },
                        [_vm._v(_vm._s(_vm.previewInfo.btn2Nm))]
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "bottom_chk_wrap" }, [
            _c("ul", [
              _c("li", [
                _c("input", {
                  attrs: { type: "checkbox", id: "chk1" },
                  on: {
                    click: function ($event) {
                      return _vm.cookieClose()
                    },
                  },
                }),
                _c("label", { attrs: { for: "chk1" } }, [
                  _vm._v(_vm._s(_vm.$t("common.NOTICE_POP.001"))),
                ]),
              ]),
              _c("li", [
                _c(
                  "button",
                  {
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.closeWindow()
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("msg.MAIN100.228")) + " ")]
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }